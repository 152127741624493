import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "../css/confirmationPage.css"

const OrderConfirmationPage = () => {
  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '60vh' }}>
      <Row className="w-100">
        <Col>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Link to="/" className="text-muted small">Return to shopping</Link>
          </div>
          
          <div className="text-center mb-4 text-secondary py-4">
            <h1 className="fw-bold mb-3">THANK YOU FOR<br />YOUR ORDER</h1>
            <p className="mt-5">
              We've emailed you an order confirmation<br />
              and we'll notify you when your order has<br />
              shipped.
            </p>
          </div>
          
          <div className="d-flex gap-3 mt-4">
            <Button variant="outline-primary" className="flex-grow-1">
              CONTINUE SHOPPING
            </Button>
            <Button variant="info" className="flex-grow-1 text-white">
              ORDER DETAILS
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderConfirmationPage;
import React, { createContext, useContext, useState, useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: 'https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/translations/{{lng}}',
    },
    debug: true, // Enable debug
  });

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const loadTranslations = async (lang) => {
    console.log(`Attempting to load translations for ${lang}`);
    try {
      await i18n.loadLanguages(lang);
      console.log(`Translations loaded for ${lang}`, i18n.getResourceBundle(lang, 'translation'));
    } catch (error) {
      console.error(`Error loading translations for ${lang}:`, error);
    }
  };

  const changeLanguage = async (lang) => {
    console.log(`Attempting to change language to ${lang}`);
    try {
      await loadTranslations(lang);
      await i18n.changeLanguage(lang);
      setLanguage(lang);
      console.log(`Language changed to ${lang}`);
    } catch (error) {
      console.error(`Error changing language to ${lang}:`, error);
    }
  };

  useEffect(() => {
    loadTranslations(language);
  }, []);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      <I18nextProvider i18n={i18n}>
        {children}
      </I18nextProvider>
    </LanguageContext.Provider>
  );
};
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CategoryValueModal = ({ show, onHide, onSave, type, options }) => {
  const [categories, setCategories] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (type === 'parts') {
      setCategories(options);
      setValues([]);
    } else if (type === 'cars') {
      const brands = [...new Set(options.map(option => option.brand))];
      setCategories(brands);
      setValues(options);
    }
  }, [type, options]);

  const handleAddCategory = () => {
    setCategories([...categories, '']);
  };

  const handleAddValue = () => {
    setValues([...values, { brand: '', label: '' }]);
  };

  const handleCategoryChange = (index, value) => {
    const newCategories = [...categories];
    newCategories[index] = value;
    setCategories(newCategories);
  };

  const handleValueChange = (index, field, value) => {
    const newValues = [...values];
    newValues[index] = { ...newValues[index], [field]: value };
    setValues(newValues);
  };

  const handleSave = () => {
    let newOptions;
    if (type === 'parts') {
      newOptions = categories.filter(category => category !== '');
    } else if (type === 'cars') {
      newOptions = values.filter(value => value.brand !== '' && value.label !== '');
    }
    onSave(newOptions);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{type === 'parts' ? 'Edit Parts' : 'Edit Cars and Brands'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Categories</h5>
        {categories.map((category, index) => (
          <Form.Control
            key={index}
            type="text"
            value={category}
            onChange={(e) => handleCategoryChange(index, e.target.value)}
            className="mb-2"
          />
        ))}
        <Button onClick={handleAddCategory} className="mb-3">Add Category</Button>

        {type === 'cars' && (
          <>
            <h5>Values</h5>
            {values.map((value, index) => (
              <div key={index} className="d-flex mb-2">
                <Form.Control
                  type="text"
                  value={value.brand}
                  onChange={(e) => handleValueChange(index, 'brand', e.target.value)}
                  placeholder="Brand"
                  className="me-2"
                />
                <Form.Control
                  type="text"
                  value={value.label}
                  onChange={(e) => handleValueChange(index, 'label', e.target.value)}
                  placeholder="Model"
                />
              </div>
            ))}
            <Button onClick={handleAddValue}>Add Value</Button>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleSave}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CategoryValueModal;
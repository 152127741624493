import React, { useRef } from 'react';
import { Navbar, Nav, Container, Row, Col, Dropdown } from 'react-bootstrap';
import { useAuth } from '../AuthContext';
import SearchBar from './SearchBar';
import Login from './login';
import ShoppingCart from './ShoppingCart';
import { ReactSVG } from "react-svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useShoppingCart } from '../contexts/ShoppingCartContext';
import { useLanguage } from '../contexts/LanguageProvider';
import { useTranslation } from 'react-i18next';

const NavigationBar = ({ data, isCartOpen, setIsCartOpen }) => {
  const scrollContainerRef = useRef(null);
  const { cartItemCount } = useShoppingCart();
  const { language, changeLanguage } = useLanguage();
  const { t } = useTranslation();

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'fr', name: 'Français' },
    // Add more languages as needed
  ];

  return (
    <header className="App-header">
      <Navbar bg="dark" variant="dark" expand="lg" className="shadow-lg zindex-modal-backdrop">
        <Navbar.Brand href="/home">
          <div className="logo">
            <ReactSVG
              style={{paddingTop: '9px'}}
              src={`${process.env.PUBLIC_URL}/svg_icon.svg`}
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 150px; height: 85px;');
              }}
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Nav className="m-auto">
          <SearchBar />
          <div className="d-flex align-items-center">
            <Dropdown className="mx-3">
              <Dropdown.Toggle variant="outline-light" id="dropdown-language">
                <FontAwesomeIcon icon={faGlobe} className="mr-2" />
                {languages.find(lang => lang.code === language)?.name || 'Language'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {languages.map((lang) => (
                  <Dropdown.Item 
                    key={lang.code} 
                    onClick={() => changeLanguage(lang.code)}
                    active={lang.code === language}
                  >
                    {lang.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Login />
            <div className="shopping-cart mx-3" onClick={() => setIsCartOpen(!isCartOpen)}>
              <FontAwesomeIcon icon={faShoppingCart} size="lg" />
              {cartItemCount > 0 && (
                <span className="cart-badge"> {cartItemCount}</span>
              )}
            </div>
          </div>
        </Nav>
      </Navbar>
      <Navbar bg="#ff6600" variant="dark" expand="lg" className="shadow zindex-sticky">
        <div className="scroll-wrapper">
          <Container fluid className="horizontal-scroll-container" ref={scrollContainerRef}>
            <Row className="flex-nowrap">
              {data.map((item, idx) => (
                <Col key={idx} className="px-4 py-1">
                  <Nav.Link href="/home">{t(item)}</Nav.Link>
                </Col>
              ))}
            </Row>
          </Container>
          <div className="fade-left"></div>
          <div className="fade-right"></div>
        </div>
      </Navbar>
    </header>
  );
};

export default NavigationBar;
// Field types definition
export const fieldTypes = {
	single: 'Single Value',
	arrayString: 'Array of Strings',
	arrayNumber: 'Array of Numbers',
	arrayBoolean: 'Array of Booleans',
	arrayColor: 'Array of Colors',
	arrayImage: 'Array of Images',
      };
      
      // Helper function to determine array type
      export const getArrayType = (arr) => {
	if (Array.isArray(arr) && arr.length > 0) {
	  const firstItem = arr[0];
	  if (typeof firstItem === 'object') {
	    if (firstItem.hasOwnProperty('image')) return 'image';
	    if (firstItem.hasOwnProperty('color')) return 'color';
	    if (firstItem.hasOwnProperty('value')) {
	      if (typeof firstItem.value === 'string') return 'string';
	      if (typeof firstItem.value === 'number') return 'number';
	      if (typeof firstItem.value === 'boolean') return 'boolean';
	    }
	  }
	}
	return 'mixed';
      };

      export const handleAddField = (newKey, newValue, fieldType, value, onChange, setNewKey, setNewValue, setFieldType) => {
	if (newKey) {
	  let processedValue;
	  switch (fieldType) {
	    case 'single':
	      processedValue = newValue;
	      break;
	    case 'arrayString':
	      processedValue = [{ en_title: '', fr_title: '', cost: 0, value: '' }];
	      break;
	    case 'arrayNumber':
	      processedValue = [{ en_title: '', fr_title: '', cost: 0, value: 0 }];
	      break;
	    case 'arrayBoolean':
	      processedValue = [{ en_title: '', fr_title: '', cost: 0, value: false }];
	      break;
	    case 'arrayColor':
	      processedValue = [{ en_title: '', fr_title: '', cost: 0, color: '#000000' }];
	      break;
	    case 'arrayImage':
	      processedValue = [{ en_title: '', fr_title: '', cost: 0, image: '', description: '' }];
	      break;
	    default:
	      processedValue = newValue;
	  }
	  onChange({
	    ...value,
	    [newKey]: processedValue,
	    [`${newKey}_en_name`]: newKey,
	    [`${newKey}_fr_name`]: '',
	    [`${newKey}_en_description`]: '',
	    [`${newKey}_fr_description`]: ''
	  });
	  setNewKey('');
	  setNewValue('');
	  setFieldType('single');
	}
      };
      
      export const handleRemoveField = (fieldKey, value, onChange) => {
	const newValue = { ...value };
	delete newValue[fieldKey];
	delete newValue[`${fieldKey}_en_name`];
	delete newValue[`${fieldKey}_fr_name`];
	delete newValue[`${fieldKey}_en_description`];
	delete newValue[`${fieldKey}_fr_description`];
	onChange(newValue);
      };
      
      export const handleEditKey = (oldKey, newKey, value, onChange) => {
	if (oldKey !== newKey && newKey.trim() !== '') {
	  const newObj = { ...value };
	  newObj[newKey] = newObj[oldKey];
	  delete newObj[oldKey];
	  
	  ['en_name', 'fr_name', 'en_description', 'fr_description'].forEach(suffix => {
	    if (newObj[`${oldKey}_${suffix}`] !== undefined) {
	      newObj[`${newKey}_${suffix}`] = newObj[`${oldKey}_${suffix}`];
	      delete newObj[`${oldKey}_${suffix}`];
	    }
	  });
	  
	  onChange(newObj);
	}
      };
      
      // Update handleEditField to work with the new structure
export const handleEditField = (fieldKey, newFieldValue, index, subField, value, onChange) => {
	if (index !== null && Array.isArray(value[fieldKey])) {
	  const newArray = [...value[fieldKey]];
	  newArray[index] = {
	    ...newArray[index],
	    [subField]: newFieldValue
	  };
	  onChange({ ...value, [fieldKey]: newArray });
	} else {
	  onChange({ ...value, [fieldKey]: newFieldValue });
	}
      };
      
      export const handleEditArrayDescription = (fieldKey, newDescription, value, onChange) => {
	onChange({
	  ...value,
	  [`${fieldKey}_description`]: newDescription
	});
      };
      
      export const handleAddToArray = (fieldKey, value, onChange) => {
	const currentArray = value[fieldKey];
	if (Array.isArray(currentArray) && currentArray.length > 0) {
	  const lastItem = currentArray[currentArray.length - 1];
	  let newItem = { ...lastItem };  // Create a copy of the last item
      
	  // Reset the values
	  if (newItem.hasOwnProperty('color')) {
	    // This is a color array
	    newItem.color = '#000000';  // Default color
	  } else if (newItem.hasOwnProperty('value')) {
	    if (typeof newItem.value === 'number') {
	      newItem.value = 0;
	    } else if (typeof newItem.value === 'boolean') {
	      newItem.value = false;
	    } else {
	      newItem.value = '';
	    }
	  } else if (newItem.hasOwnProperty('image')) {
	    newItem.image = '';
	    newItem.description = '';
	  }
      
	  // Reset other fields
	  newItem.en_title = '';
	  newItem.fr_title = '';
	  newItem.cost = 0;
      
	  onChange({ ...value, [fieldKey]: [...currentArray, newItem] });
	}
      };
      
      export const handleRemoveFromArray = (fieldKey, index, value, onChange) => {
	const currentArray = value[fieldKey];
	if (Array.isArray(currentArray)) {
	  const newArray = currentArray.filter((_, i) => i !== index);
	  onChange({ ...value, [fieldKey]: newArray });
	}
      };
      
      export const handleColorChange = (fieldKey, index, newColor, value, onChange) => {
	handleEditField(fieldKey, newColor.hex, index, value, onChange);
      };
      
      export const handleImageUpload = (event, fieldKey, index, value, onChange) => {
	const file = event.target.files[0];
	if (file) {
	  const reader = new FileReader();
	  reader.onloadend = () => {
	    const newImage = { 
	      image: reader.result, 
	      description: value[fieldKey][index]?.description || '' 
	    };
	    handleEditField(fieldKey, newImage, index, value, onChange);
	  };
	  reader.readAsDataURL(file);
	}
      };
      
      export const handleImageDescriptionChange = (fieldKey, index, description, value, onChange) => {
	const newArray = [...value[fieldKey]];
	newArray[index] = { ...newArray[index], description };
	onChange({ ...value, [fieldKey]: newArray });
      };
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './SearchBar.css';

const SearchBar = () => {
  return (
    <Container className="flex-column justify-content-center rounded">
      <Form className="d-flex mt-3">
	<InputGroup className="mb-3">
		<Form.Control
			type="text"
			placeholder="Search"
			aria-label="Search"
			aria-describedby="search1"
			className="rounded-left bg-dark text-white outlined-secondary border-secondary"
		/>
		<Button className="rounded-right" variant="outline-secondary" id="button-addon1">
			<FontAwesomeIcon icon="search" />
		</Button>
	</InputGroup>
      </Form>
    </Container>
  );
};

export default SearchBar;
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../AuthContext';

const ImageModal = ({ id }) => {
  const { getToken } = useAuth();
  const [showImageModal, setShowImageModal] = useState(false);
  const [images, setImages] = useState([]);
  const [retrievedImages, setRetrievedImages] = useState([]);
  const [toDelete, setToDelete] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchImages();
  }, [id]); // Fetch images when the component mounts or when id changes

  const fetchImages = async () => {
    const token = await getToken();
    try {
      const response = await fetch(`https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/listImages?folder=${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.ok) {
        const data = await response.json();
        setRetrievedImages(data);
        console.log('Images retrieved successfully:', data);
      } else {
        console.error('Failed to retrieve images:', response.statusText);
      }
    } catch (error) {
      console.error('Error retrieving images:', error);
    }
  };

  const handleOpenModal = () => {
    setShowImageModal(true);
  };

  const handleClose = () => {
    setShowImageModal(false);
  };

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...selectedFiles]);
  };

  const handleRemoveImage = (index, fromFetched) => {
    if (fromFetched) {
      setToDelete((prevImages) => [...prevImages, retrievedImages[index]]);
      setRetrievedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    } else {
      setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if(toDelete.length > 0) {
        await handleDelete();
      } 
      if(images.length > 0) {
        await handleUpload();
      }
      await fetchImages(); // Refresh images after save
    } catch (error) {
      console.error('Error during save operation:', error);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  }
  
  const handleUpload = async () => {
    const token = await getToken();
    const formData = new FormData();
    images.forEach((image) => {
      formData.append('images', image);
    });
    formData.append('folder', `${id}`);

    try {
      const response = await fetch('https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/uploadImages', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Upload successful:', data);
        setImages([]);
        handleClose();
      } else {
        console.error('Upload failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  };

  const handleDelete = async () => {
    const token = await getToken();
    try {
      await Promise.all(
        toDelete.map(async (imageUrl) => {
          const imageName = imageUrl.split('/').pop();
          const response = await fetch('https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/deleteImage', {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ folder: id, image: imageName })
          });
        
          if (!response.ok) {
            console.error('Failed to delete image:', response.statusText);
          }
        })
      );
      setToDelete([]);
      handleClose();
    } catch (error) {
      console.error('Error deleting images:', error);
    }
  };

  const combinedImages = [
    ...images.map((file, index) => ({ file, isNew: true, index: retrievedImages.length + index })),
    ...retrievedImages.map((url, index) => ({ url, isNew: false, index })),
  ];

  const imagePreviewStyle = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: combinedImages.length > 2 ? '100px' : 'auto',
    overflowX: combinedImages.length > 2 ? 'scroll' : 'visible',
    flexWrap: combinedImages.length <= 2 ? 'nowrap' : 'none',
  };

  return (
    <>
      {combinedImages.length > 0 ? (
        <div style={imagePreviewStyle} onClick={handleOpenModal}>
          {combinedImages.map((image, index) => (
            <div
              key={index}
              style={{
                border: '1px solid white',
                position: 'relative',
                flex: '0 0 auto',
                marginRight: '5px', // Add some space between images
              }}
            >
              <img
                className='shadow bg-white rounded'
                src={image.isNew ? URL.createObjectURL(image.file) : image.url}
                alt={`Selected ${index}`}
                style={{ width: '40px', height: '40px', borderRadius: '5px', objectFit: 'cover' }}
              />
            </div>
          ))}
        </div>
      ) : (
        <button className="btn btn-secondary" onClick={handleOpenModal}>Add Image</button>
      )}

      <Modal show={showImageModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {combinedImages.length > 0 && (
		<div className="image-list-container border rounded" style={{ display: 'flex', overflowX: 'scroll', marginBottom: '10px', padding: '10px' }}>
			{combinedImages.map((image, index) => (
				<div key={index} style={{ position: 'relative', flex: '0 0 auto', marginRight: '10px' }}>
					<img
						className='shadow bg-white rounded'
						src={image.isNew ? URL.createObjectURL(image.file) : image.url}
						alt={`Selected ${index}`}
						style={{ width: '100px', height: '100px', borderRadius: '15px', objectFit: 'cover' }}
					/>
					<div
						onClick={() => handleRemoveImage(image.index, !image.isNew)}
						style={{
							position: 'absolute',
							top: '0px',
							right: '-5px',
							width: '25px',
							height: '25px',
						}}
					><FontAwesomeIcon icon="times-circle" className='text-light' /></div>
                		</div>
			))}
		</div>
	)}
          <Form>
            <Form.Group controlId="formImage">
              <Form.Control type="file" multiple name="image" onChange={handleImageChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="ml-2 ps-2">Saving...</span>
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageModal;
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ShoppingCartProvider, useShoppingCart } from './contexts/ShoppingCartContext';
import { LanguageProvider } from './contexts/LanguageProvider';
import NavigationBar from './components/NavigationBar';
import PrivateRoute from './components/PrivateRoute';
import AdminDashboard from './pages/AdminDashboard';
import PublicPage from './pages/PublicPage';
import ProductPage from './pages/ProductPage';
import ShoppingCart from './components/ShoppingCart';
import CheckoutPage from './pages/Checkout';
import OrderConfirmationPage from './pages/order-confirmation';

const AppContent = () => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const data = ['Home', 'Products', 'Services', 'Contact'];
  const { cart, cartTotal } = useShoppingCart();

  useEffect(() => {
    console.log('Cart updated in App:', cart);
    console.log('Cart total:', cartTotal);
    console.log('localStorage shoppingCart:', localStorage.getItem('shoppingCart'));
  }, [cart, cartTotal]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      document.body.style.marginRight = isCartOpen ? '300px' : '0';
      document.body.style.transition = 'margin-right 0.3s ease-in-out';
    } else {
      document.body.style.marginRight = '0';
    }
  }, [isCartOpen, isMobile]);

  return (
    <Router>
      <div className={`App ${isCartOpen && !isMobile ? 'cart-open' : ''}`}>
        <NavigationBar
          data={data}
          isCartOpen={isCartOpen}
          setIsCartOpen={setIsCartOpen}
        />
        <ShoppingCart
          isOpen={isCartOpen}
          setIsOpen={setIsCartOpen}
          isMobile={isMobile}
        />
        <main className="main-content">
          <Routes>
            <Route path="/admin/dashboard" element={<PrivateRoute requiredRole="admin"><AdminDashboard /></PrivateRoute>} />
            <Route path="/product/:id" element={<ProductPage />} />
            <Route path="/" element={<PublicPage />} />
            <Route path="/checkout" element={<CheckoutPage />} /> {/* Add this line */}
            <Route path="/order-confirmation/:orderId" element={<OrderConfirmationPage />} />
            {/* Add other routes as needed */}
          </Routes>
        </main>
      </div>
    </Router>
  );
};

const App = () => {
  return (
    <LanguageProvider>
      <ShoppingCartProvider>
        <AppContent />
      </ShoppingCartProvider>
    </LanguageProvider>
  );
};

export default App;
export const fetchOrders = async (getToken) => {
	try {
	  const token = await getToken();
	  const response = await fetch('https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/orders', {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Authorization': `Bearer ${token}`,
	      'Content-Type': 'application/json'
	    },
	    body: JSON.stringify({ action: 'fetchOrders' })
	  });
      
	  if (!response.ok) {
	    throw new Error(`Network response was not ok: ${response.statusText}`);
	  }
      
	  return await response.json();
	} catch (error) {
	  console.error('Error fetching Orders:', error);
	  throw error;
	}
};
// utils.js

export const fieldTypes = {
	single: 'Single Value',
	arrayString: 'Array of Strings',
	arrayNumber: 'Array of Numbers',
	arrayBoolean: 'Array of Booleans',
	arrayColor: 'Array of Colors',
	arrayImage: 'Array of Images',
      };
      
      export const getArrayType = (arr) => {
	if (Array.isArray(arr)) {
	  if (arr.every(item => typeof item === 'object' && item.image !== undefined)) return 'image';
	  if (arr.every(item => typeof item === 'object' && item.color !== undefined)) return 'color';
	  if (arr.every(item => typeof item === 'string')) return 'string';
	  if (arr.every(item => typeof item === 'number')) return 'number';
	  if (arr.every(item => typeof item === 'boolean')) return 'boolean';
	}
	return 'mixed';
      };
      
      export const createInitialArrayItem = (type) => {
	switch (type) {
	  case 'arrayString':
	    return '';
	  case 'arrayNumber':
	    return 0;
	  case 'arrayBoolean':
	    return false;
	  case 'arrayColor':
	    return { en_title: '', fr_title: '', cost: 0, color: '#000000' };
	  case 'arrayImage':
	    return { en_title: '', fr_title: '', cost: 0, image: '', description: '' };
	  default:
	    return null;
	}
      };
      
      // You can add any other utility functions here
      
      // For example, if you need a function to validate field names:
      export const isValidFieldName = (name) => {
	return /^[a-zA-Z_][a-zA-Z0-9_]*$/.test(name);
      };
      
      // Or a function to format currency:
      export const formatCurrency = (amount) => {
	return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
      };
      
      // Add any other utility functions your application might need
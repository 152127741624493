import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../AuthContext';
import OrderTable from './OrderTable';
import { fetchOrders, addProduct, updateProduct, deleteProduct } from '../api/ordersApi';

const OrdersTab = () => {
  const [orders, setOrders] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchAndSetOrders = async () => {
      try {
        const fetchedOrders = await fetchOrders(getToken);
        setOrders(fetchedOrders);
        console.log(JSON.stringify(fetchedOrders))
      } catch (error) {
        console.error('Error in fetchAndSetOrders:', error);
      }
    };

    fetchAndSetOrders();
  }, [getToken, refreshTrigger]);

  const refreshProducts = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  return (
    <div className="container">
      <OrderTable 
        orders={orders} 
      />
    </div>
  );
};

export default OrdersTab;
import React, { useState } from 'react';
import { Form, Button, Dropdown, Alert } from 'react-bootstrap';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../AuthContext';

function Login() {
  const { currentUser, login, signup, logout, role } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  
  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await login(email, password);
    } catch (error) {
      setError('Failed to log in');
      console.error(error);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signup(email, password);
    } catch (error) {
      setError('Failed to sign up');
      console.error(error);
    }
  };

  const handleLogout = async () => {
    setError('');
    try {
      await logout();
    } catch (error) {
      setError('Failed to log out');
      console.error(error);
    }
  };

  return (
	<NavDropdown align="end" expand="lg" className="nav-item my-auto" title={
		<>
		  <FontAwesomeIcon icon="user" style={{ cursor: 'pointer' }} />
		  {currentUser && (
		    <span className="current-user" style={{ marginLeft: '5px', marginRight: '25px' }}>
		      {currentUser.email}
		    </span>
		  )}
		</>
	      } id="basic-nav-dropdown">
		{error && <Alert variant="danger">{error}</Alert>}
		{!currentUser ? (
			<>
				<Form onSubmit={handleLogin}>
					<Form.Group className="mb-3" controlId="exampleDropdownFormEmail1">
						<Form.Label>Email address</Form.Label>
						<Form.Control
							type="email"
							placeholder="email@example.com"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="exampleDropdownFormPassword1">
						<Form.Label>Password</Form.Label>
						<Form.Control
							type="password"
							placeholder="Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="dropdownCheck">
						<Form.Check type="checkbox" label="Remember me" />
					</Form.Group>

					<Button type="submit" variant="primary">Sign in</Button>
				</Form>
				<NavDropdown.Divider />
				<NavDropdown.Item style={{width:'10vw'}} href="#" onClick={handleSignup}>Signup</NavDropdown.Item>
				<NavDropdown.Item href="#">Forgot password?</NavDropdown.Item>
				</>
			) : (
				<>
				<div className="mb-3">
					<div className="row no-gutters">
						<div className="col-md-4">
							<img src="..." className="card-img" alt="..." />
						</div>
						<div className="col-md-8">
							<div className="card-body">
							<h5 className="card-title">Card title</h5>
							<p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
							<p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
							</div>
						</div>
					</div>
				</div>
				<NavDropdown.Divider />
					{role === 'admin' && (
						<>
							<NavDropdown.Item style={{width:'10vw'}} href="/admin/dashboard">Admin Dashboard</NavDropdown.Item>
						</>
					)}
				<NavDropdown.Item style={{width:'10vw'}} href="#" onClick={handleLogout}>Logout</NavDropdown.Item>
				</>
			)}
	</NavDropdown>
  );
}

export default Login;

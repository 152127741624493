import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ImageModal from './ImageModal';
import '../css/OrderTable.css'; // Import the CSS file

const ProductTable = ({ products, onEdit, onDelete }) => {
  // Function to extract only the car models from the cars string
  const extractCarModels = (carsString) => {
    if (!carsString) return '';
    return carsString.split(',')
      .map(car => car.split(':')[1]?.trim())
      .filter(Boolean)
      .join(', ');
  };

  return (
    <>
      <div className="table-container" style={{ height: '500px'}} >
        <div className="text-start" style={{ height: '480px', overflowY: 'auto' }}>
          <Table style={{marginTop: 0}}>
            <thead style={{ position: 'sticky', top: 0}} className="table-header-font">
              <tr>
                <th className="col-xs-2 table-header-font">Name</th>
                <th className="col-xs-2 table-header-font">Description</th>
                <th className="col-xs-2 table-header-font">Price</th>
                <th className="col-xs-2 table-header-font">Recommend</th>
                <th className="col-xs-2 table-header-font">Part</th>
                <th className="col-xs-2 table-header-font">Cars</th>
                <th className="col-xs-2 table-header-font">Brands</th>
                <th className="col-xs-2 table-header-font">Specifics</th>
                <th className="col-xs-1 table-header-font text-center">Add Image</th>
                <th className="col-xs-1 table-header-font text-center">Edit</th>
                <th className="col-xs-1 table-header-font text-center">Delete</th>
              </tr>
            </thead>
            <tbody>
              {products.map(product => (
                <tr key={product.id}>
                  <td className="col-xs-2">
                    <Link to={`/product/${product.id}`}>{product.name_en || product.name}</Link>
                  </td>
                  <td 
                    className="text-truncate col-xs-2" 
                    style={{ maxWidth: '150px' }} 
                    title={product.description_en || product.description}
                  >
                    {product.description_en || product.description}
                  </td>
                  <td className="col-xs-2">{product.price}</td>
                  <td className="col-xs-2">{product.recommend}</td>
                  <td className="col-xs-2">{product.part}</td>
                  <td className="col-xs-2">{extractCarModels(product.cars)}</td>
                  <td className="col-xs-2">{product.brands}</td>
                  <td 
                    className="text-truncate col-xs-2" 
                    style={{ maxWidth: '150px' }} 
                    title={product.specifics}
                  >
                    {product.specifics}
                  </td>
                  <td className="col-xs-2">
                    <ImageModal id={product.id} />
                  </td>
                  <td className="col-xs-2">
                    <Button variant="warning" onClick={() => onEdit(product)}>
                      <FontAwesomeIcon icon="gears" />
                    </Button>
                  </td>
                  <td className="col-xs-2">
                    <Button variant="danger" onClick={() => onDelete(product)}>
                      <FontAwesomeIcon icon="x" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default ProductTable;
import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import '../css/OrderTable.css'; // Import the CSS file
import '../css/invoiceModal.css'

const OrderTable = ({ orders, onEdit, onDelete }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentInvoiceURL, setCurrentInvoiceURL] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getToken } = useAuth();
  const { t } = useTranslation();

  function firestoreTimestampToDate(timestamp) {
    const date = new Date(timestamp._seconds * 1000);
    date.setMilliseconds(date.getMilliseconds() + timestamp._nanoseconds / 1000000);
    return date;
  }

  const handleShowInvoice = async (orderId) => {
    const token = await getToken();
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/invoice/${orderId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setCurrentInvoiceURL(data.invoiceUrl);
      setShowModal(true);
    } catch (err) {
      console.error('Error fetching invoice:', err);
      setError('Failed to fetch invoice. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentInvoiceURL('');
    setError(null);
  };

  const renderProductDetails = (products) => {
    if (products.length <= 2) {
      return products.map(product => (
        <div key={product.id} className="product-item text-secondary text-start">
          {`(${product.quantity}x) ${product['name_en']}`}
        </div>
      ));
    } else {
      const displayedProducts = products.slice(0, 2);
      const remainingProducts = products.slice(2);

      const popover = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">All Products</Popover.Header>
          <Popover.Body>
            {products.map(product => (
              <div key={product.id}>{product.id}</div>
            ))}
          </Popover.Body>
        </Popover>
      );

      return (
        <>
          {displayedProducts.map(product => (
            <div key={product.id} className="product-item text-secondary text-start">
              {`(${product.quantity}x) ${product.id}`}
            </div>
          ))}
          <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <div className="product-item text-secondary text-start" style={{ cursor: 'pointer' }}>
              ...
            </div>
          </OverlayTrigger>
        </>
      );
    }
  };

  return (
    <>
    <div className="table-container" >
      <div className="text-start" style={{ height: '375px', overflowY: 'auto' }}>
            <Table style={{marginTop: 0}}>
              <thead style={{ position: 'sticky', top: 0}} className="table-header-font">
                <tr>
                  <th className="col-xs-2 table-header-font">Client</th>
                  <th className="col-xs-2 table-header-font">Date</th>
                  <th className="col-xs-2 table-header-font">Size</th>
                  <th className="col-xs-2 table-header-font">Products</th>
                  <th className="col-xs-2 table-header-font">Status</th>
                  <th className="col-xs-1 table-header-font text-center">Invoice</th>
                  <th className="col-xs-1 table-header-font text-center">Shipping</th>
                </tr>
              </thead>
              <tbody>
                {orders.map(order => (
                  <tr key={order.id}>
                    <td className="col-xs-2">{order.userId}</td>
                    <td className="col-xs-2">{firestoreTimestampToDate(order.createdAt).toUTCString()}</td>
                    <td className="col-xs-2">
                      {order.products.reduce((total, product) => total + (product.quantity || 0), 0)}
                    </td>
                    <td className="col-xs-2">
                      <div className="products-column">
                        {order.products && renderProductDetails(order.products)}
                      </div>
                    </td>
                    <td className="col-xs-2">{order.status}</td>
                    <td className="text-center col-xs-1">
                      <Button 
                        onClick={() => handleShowInvoice(order.id)} 
                        disabled={isLoading || !order.invoiceUrl}
                        variant="outline-primary"
                        size="sm"
                      >
                        {order.invoiceUrl ? (
                          <FontAwesomeIcon icon="file-pdf" />
                        ) : (
                          <FontAwesomeIcon icon="plus" />
                        )}
                      </Button>
                    </td>
                    <td className="text-center col-xs-1">
                      <Button 
                        onClick={() => handleShowInvoice(order.id)} 
                        disabled={isLoading || !order.invoiceUrl}
                        variant="outline-primary"
                        size="sm"
                      >
                        {order.invoiceUrl ? (
                          <FontAwesomeIcon icon="file-pdf" />
                        ) : (
                          <FontAwesomeIcon icon="plus" />
                        )}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} size="xl" dialogClassName="modal-95w modal-95h">
        <Modal.Header closeButton>
          <Modal.Title>Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-extra-tall">
          {error ? (
            <div className="alert alert-danger">{error}</div>
          ) : isLoading ? (
            <div className="text-center">Loading invoice...</div>
          ) : (
            <iframe
              src={currentInvoiceURL}
              width="100%"
              height="100%"
              style={{ border: 'none' }}
              title="Invoice PDF"
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderTable;
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, OverlayTrigger, Popover, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MultiSelect from './MultiSelect';
import JSONBuilder from './JSONBuilder/JSONBuilder';
import CategoryValueModal from './CategoryValueModal';

const ProductModal = ({ show, onHide, product, onSave, carOptions, partsOptions, mode = 'add' }) => {
  const [editedProduct, setEditedProduct] = useState(product || {
    name: '',
    description: '',
    name_en: '',
    name_fr: '',
    description_en: '',
    description_fr: '',
    price: 0,
    stock: 0,
    orderType: 'inStock',
    recommend: '',
    addBar: '',
    part: '',
    cars: '',
    brands: '',
    specifics: '{}'
  });
  const [selectedCarsAndBrands, setSelectedCarsAndBrands] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);
  const [specifics, setSpecifics] = useState({});
  const [showPopover1, setShowPopover1] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const descriptionPopover = (
    <Popover id="popover-description">
      <Popover.Header as="h3">Description Guidelines</Popover.Header>
      <Popover.Body>
        <h6>Wrap the chunk of text with the escape character to apply the text modification.</h6>
        <p>/b : <strong>make text bold</strong></p>
        <p>/i : <i>Make text italicized</i></p>
        <p>/u : <span style={{ textDecoration: 'underline' }}>Underline the text</span></p>
      </Popover.Body>
    </Popover>
  );

  const parseDescription = (description) => {
    return description
      .replace(/\/b(.*?)\/b/g, '<strong>$1</strong>')
      .replace(/\/i(.*?)\/i/g, '<i>$1</i>')
      .replace(/\/u(.*?)\/u/g, '<span style="text-decoration: underline;">$1</span>')
      .replace(/\n/g, '<br>');
  };

  const reverseParseDescription = (description) => {
    return description
      .replace(/<strong>(.*?)<\/strong>/g, '/b$1/b')
      .replace(/<i>(.*?)<\/i>/g, '/i$1/i')
      .replace(/<span style="text-decoration: underline;">(.*?)<\/span>/g, '/u$1/u')
      .replace(/<br>/g, '\n');
  };

  useEffect(() => {
    if (product && mode === 'edit') {
      const reverseParsedProduct = {
        ...product,
        description: reverseParseDescription(product.description || ''),
        description_en: reverseParseDescription(product.description_en || ''),
        description_fr: reverseParseDescription(product.description_fr || '')
      };
      setEditedProduct(reverseParsedProduct);

      if (product.cars) {
        const carBrandArray = product.cars.split(',').map(item => {
          const [brand, model] = item.trim().split(':');
          if (brand && model) {
            return { value: model.toLowerCase(), label: model, brand };
          }
          return null;
        }).filter(Boolean);
        setSelectedCarsAndBrands(carBrandArray);
      } else {
        setSelectedCarsAndBrands([]);
      }

      if (product.part) {
        const partArray = product.part.split(',').map(part => ({
          value: part.trim().toLowerCase(),
          label: part.trim()
        }));
        setSelectedParts(partArray);
      } else {
        setSelectedParts([]);
      }

      if (product.specifics) {
        try {
          const parsedSpecifics = JSON.parse(product.specifics);
          setSpecifics(parsedSpecifics);
        } catch (error) {
          console.error('Error parsing specifics JSON:', error);
          setSpecifics({});
        }
      } else {
        setSpecifics({});
      }
    } else {
      resetForm();
    }
  }, [product, mode]);

  const resetForm = () => {
    setEditedProduct({
      name: '',
      description: '',
      name_en: '',
      name_fr: '',
      description_en: '',
      description_fr: '',
      price: 0,
      stock: 0,
      orderType: 'inStock',
      recommend: '',
      addBar: '',
      part: '',
      cars: '',
      brands: '',
      specifics: '{}'
    });
    setSelectedCarsAndBrands([]);
    setSelectedParts([]);
    setSpecifics({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct(prev => ({ ...prev, [name]: value }));
  };

  const handleOpenCategoryModal = (type) => {
    setModalType(type);
    setShowCategoryModal(true);
  };

  const handleCloseCategoryModal = () => {
    setShowCategoryModal(false);
  };

  const handleCategoryValueSave = (newOptions) => {
    if (modalType === 'parts') {
      // Update partsOptions
      // You might need to lift this state up or use a context/redux to update the parent component
      console.log('New parts options:', newOptions);
    } else if (modalType === 'cars') {
      // Update carOptions
      console.log('New car options:', newOptions);
    }
    setShowCategoryModal(false);
  };

  const handleCarsAndBrandsChange = (selectedOptions) => {
    setSelectedCarsAndBrands(selectedOptions);
    
    const carsString = selectedOptions.map(option => `${option.brand}:${option.label}`).join(', ');
    
    const uniqueBrands = new Set(selectedOptions.map(option => option.brand));
    const brandsString = Array.from(uniqueBrands).join(', ');
    
    setEditedProduct(prev => ({ 
      ...prev, 
      cars: carsString, 
      brands: brandsString 
    }));
  };

  const handlePartsChange = (selectedOptions) => {
    setSelectedParts(selectedOptions);
    
    const partsString = selectedOptions.map(option => option.label).join(', ');
    
    setEditedProduct(prev => ({ 
      ...prev, 
      part: partsString
    }));
  };

  const handleSpecificsChange = (newSpecifics) => {
    setSpecifics(newSpecifics);
    setEditedProduct(prev => ({
      ...prev,
      specifics: JSON.stringify(newSpecifics)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const parsedProduct = {
      ...editedProduct,
      description: parseDescription(editedProduct.description || ''),
      description_en: parseDescription(editedProduct.description_en || ''),
      description_fr: parseDescription(editedProduct.description_fr || '')
    };
    onSave(parsedProduct);
    if (mode === 'add') {
      resetForm();
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{mode === 'add' ? 'Add New Product' : 'Edit Product'}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col md={6} className='border-end pe-3 pt-2'>
              <h5>EN</h5>
              <Form.Group controlId="formName" className="mb-3 pt-2">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name_en"
                  value={editedProduct.name_en || ''}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formDescription" className="mb-3">
                <Form.Label className="d-flex justify-content-between align-items-center">
                  <span>Description</span>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={descriptionPopover}
                    show={showPopover1}
                    onToggle={() => setShowPopover1(!showPopover1)}
                  >
                    <Button 
                      variant="outline-secondary" 
                      size="sm" 
                      className="ms-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPopover1(!showPopover1);
                      }}
                    >
                      <FontAwesomeIcon icon="clipboard" />
                    </Button>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="description_en"
                  value={editedProduct.description_en || ''}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6} className='ps-3 pt-2'>
              <h5>FR</h5>
              <Form.Group controlId="formName" className="mb-3 pt-2">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name_fr"
                  value={editedProduct.name_fr || ''}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formDescription" className="mb-3">
                <Form.Label className="d-flex justify-content-between align-items-center">
                  <span>Description</span>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={descriptionPopover}
                    show={showPopover2}
                    onToggle={() => setShowPopover2(!showPopover2)}
                  >
                    <Button 
                      variant="outline-secondary" 
                      size="sm" 
                      className="ms-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPopover2(!showPopover2);
                      }}
                    >
                      <FontAwesomeIcon icon="clipboard" />
                    </Button>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="description_fr"
                  value={editedProduct.description_fr || ''}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formPrice" className="mb-3">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  value={editedProduct.price || 0}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formStock" className="mb-3">
                <Form.Label>Stock</Form.Label>
                <Form.Control
                  type="number"
                  name="stock"
                  value={editedProduct.stock || 0}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formOrderType" className="mb-3">
                <Form.Label>Order Type</Form.Label>
                <Form.Select
                  name="orderType"
                  value={editedProduct.orderType || 'In Stock'}
                  onChange={handleChange}
                  required
                >
                  <option value="inStock">In Stock</option>
                  <option value="outOfStock">Out of Stock</option>
                  <option value="customOrder">Custom Order</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="formRecommend" className="mb-3">
                <Form.Label>Recommend</Form.Label>
                <Form.Control
                  type="text"
                  name="recommend"
                  value={editedProduct.recommend || ''}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formParts" className="mb-3">
                <Form.Label className="d-flex justify-content-between align-items-center">
                  <span>Parts</span>
                  <Button 
                    variant="outline-secondary" 
                    size="sm" 
                    className="ms-2"
                    onClick={() => handleOpenCategoryModal('parts')}
                  >
                    <FontAwesomeIcon icon="plus" />
                  </Button>
                </Form.Label>
                <MultiSelect
                  value={selectedParts}
                  onChange={handlePartsChange}
                  options={partsOptions}
                  placeholder={"Select part..."}
                />
              </Form.Group>
              <Form.Group controlId="formSpecifics" className="mb-4">
                <Form.Label>Specifics</Form.Label>
                <JSONBuilder value={specifics} onChange={handleSpecificsChange} />
              </Form.Group>
              <Form.Group controlId="formCarsAndBrands" className="mb-3">
                <Form.Label className="d-flex justify-content-between align-items-center">
                  <span>Cars and Brands</span>
                  <Button 
                    variant="outline-secondary" 
                    size="sm" 
                    className="ms-2"
                    onClick={() => handleOpenCategoryModal('cars')}
                  >
                    <FontAwesomeIcon icon="plus" />
                  </Button>
                </Form.Label>
                <MultiSelect
                  value={selectedCarsAndBrands}
                  onChange={handleCarsAndBrandsChange}
                  options={carOptions}
                  placeholder={"Select cars and brands..."}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            {mode === 'add' ? 'Add Product' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Form>
      <CategoryValueModal 
        show={showCategoryModal}
        onHide={handleCloseCategoryModal}
        onSave={handleCategoryValueSave}
        type={modalType}
        options={modalType === 'parts' ? partsOptions : carOptions}
      />
    </Modal>
  );
};

export default ProductModal;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useLanguage } from '../contexts/LanguageProvider';
import { useShoppingCart } from '../contexts/ShoppingCartContext';
import { useTranslation } from 'react-i18next';
import '../css/productPage.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const ProductPage = () => {
  const [product, setProduct] = useState(null);
  const [images, setImages] = useState([]);
  const [orderNotes, setOrderNotes] = useState('');
  const [error, setError] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const { id } = useParams();
  const { getToken } = useAuth();
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { addToCart } = useShoppingCart();
  
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const token = await getToken();
        console.log('Fetching product with ID:', id);
        const response = await fetch(`https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/product/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
          }
        });
        console.log('Response status:', response.status);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched product data:', data);
        setProduct(data);

        // Initialize selectedOptions with the first option of each array
        if (data.specifics) {
          const parsedSpecifics = JSON.parse(data.specifics);
          console.log(parsedSpecifics)
          const initialSelectedOptions = {};
          Object.entries(parsedSpecifics).forEach(([key, value]) => {
            if (Array.isArray(value) && value.length > 0) {
              initialSelectedOptions[key] = value[0];
            }
          });
          setSelectedOptions(initialSelectedOptions);
        }
      } catch (error) {
        console.error('Error fetching product:', error);
        setError(error.message);
      }
    };
    
    const fetchImages = async () => {
      try {
        const token = await getToken();
        console.log('Fetching images for product ID:', id);
        const response = await fetch(`https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/listImages?folder=${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
          }
        });
        console.log('Image response status:', response.status);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched image data:', data);
        setImages(data);
      } catch (error) {
        console.error('Error fetching images:', error);
        setError(error.message);
      }
    };
    
    fetchProduct();
    fetchImages();
  }, [id, getToken]);

  useEffect(() => {
    if (product) {
      calculateTotalPrice();
    }
  }, [product, selectedOptions]);

  const handleAddToCart = () => {
    if (product) {
      const itemToAdd = {
        id: product.id,
        name_en: product[`name_en`] || product.name,
        name_fr: product[`name_fr`] || product.name,
        price: parseFloat(totalPrice),
        unitPrice: parseFloat(product.price),
        image: images[0], // Assuming the first image is the main product image
        quantity: quantity,
        selectedOptions: selectedOptions,
        notes: orderNotes,
      };
      console.log(itemToAdd)
      addToCart(itemToAdd);
      // Optionally, you can add a confirmation message or modal here
    }
  };

  const handleOptionChange = (key, value) => {
    setSelectedOptions(prevOptions => ({
      ...prevOptions,
      [key]: value
    }));
  };

  const isColor = (strColor) => {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== '';
  }

  const calculateTotalPrice = () => {
    let price = parseFloat(product.price);
    
    Object.entries(selectedOptions).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null && 'cost' in value) {
        price += parseFloat(value.cost);
      }
    });

    setTotalPrice(price.toFixed(2));
  };

  const renderSpecifics = () => {
    if (!product || !product.specifics) return null;

    let specifics;
    try {
      specifics = JSON.parse(product.specifics);
    } catch (error) {
      console.error('Error parsing specifics JSON:', error);
      return <p>Error loading specifics</p>;
    }

    const renderSpecificItem = (key, value, description) => {
      if (Array.isArray(value)) {
        if (typeof value[0] === 'object' && value[0] !== null) {
          // Handle the new format (array of objects)
          const titleKey = `${key}_${language}_name` in specifics ? `${key}_${language}_name` : `${key}_en_name`;
          const descriptionKey = `${key}_${language}_description` in specifics ? `${key}_${language}_description` : `${key}_en_description`;
          return (
            <div key={key} className="specific-item">
              <strong>{specifics[titleKey] || key}:</strong>
              <div className="option-group">
                {value.map((item, index) => {
                  const isColorOption = isColor(item.color || item.value);
                  return (
                    <div
                      key={index}
                      className={`option-tile ${JSON.stringify(selectedOptions[key]) === JSON.stringify(item) ? 'selected' : ''} ${isColorOption ? 'color-tile' : ''}`}
                      onClick={() => handleOptionChange(key, item)}
                      style={isColorOption ? { backgroundColor: item.color || item.value } : {}}
                    >
                      {!isColorOption && (
                        <>
                          <span className="option-text">{item[`${language}_title`] || item.en_title}</span>
                          {item.image && <img src={item.image} alt={item[`${language}_title`] || item.en_title} className="option-image" />}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
              {specifics[descriptionKey] && <p className="option-description">{specifics[descriptionKey]}</p>}
            </div>
          );
        } else {
          // Handle the current format (array of strings)
          return (
            <div key={key} className="specific-item">
              <strong>{key}:</strong>
              <div>
                <div className="option-group">
                  {value.map((option, index) => {
                    const isColorOption = isColor(option);
                    return (
                      <div
                        key={index}
                        className={`option-tile ${selectedOptions[key] === option ? 'selected' : ''} ${isColorOption ? 'color-tile' : ''}`}
                        onClick={() => handleOptionChange(key, option)}
                        style={isColorOption ? { backgroundColor: option } : {}}
                      >
                        {!isColorOption && <span className="option-text">{option}</span>}
                      </div>
                    );
                  })}
                </div>
                {description && <p className="option-description">{description}</p>}
              </div>
            </div>
          );
        }
      } else {
        // Handle simple string values
        return (
          <div key={key} className="specific-item">
            <strong>{key}:</strong> <span>{value}</span>
            {description && <p className="option-description">{description}</p>}
          </div>
        );
      }
    };

    return (
      <div className="specifics-section">
        <h2>Product Specifics</h2>
        {Object.entries(specifics).map(([key, value]) => {
          if (!key.includes('_name') && !key.includes('_description')) {
            const description = specifics[`${key}_description`];
            return renderSpecificItem(key, value, description);
          }
          return null;
        })}
      </div>
    );
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container item-page">
      <div className="row">
        <div className="col-md-4 item-image">
          <Carousel dynamicHeight={false} showThumbs={true}>
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  className="img-fluid"
                  alt={`${product[`name_${language}`] || product.name} ${index + 1}`}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="col-md-5 px-2 mb-3 item-details">
          <h1 className="item-title">{product[`name_${language}`] || product.name}</h1>
          <div className="item-rating text-warning">
            ★★★★☆ (4.0 out of 5 stars) | 1,234 ratings
          </div>
          <div className="item-price text-dark">
            ${totalPrice}
          </div>
          <div className="item-availability text-success">
            {t(product['orderType'])}
          </div>
          <div className="item-description">
            <h2>{t('aboutItem')}</h2>
            <div dangerouslySetInnerHTML={{ __html: product[`description_${language}`] || product.description }} />
            <ul>
              <li>{product.recommend}</li>
              <li>{product.addBar}</li>
              <li>{product.part}</li>
            </ul>
          </div>
          {renderSpecifics()}
        </div>
        <div className="col-md-3 px-2 ms-5 mb-3">
          <div className="shipping-info sticky-top">
            <div className="ship-to">
              <h3>Total: </h3>
              <div className="item-price text-dark">
            ${totalPrice}
          </div>
            </div>
            <div className="security-privacy">
              <h4>Order Details</h4>
              <textarea
                className="form-control"
                rows="4"
                placeholder="Add any special instructions or notes for your order here..."
                value={orderNotes}
                onChange={(e) => setOrderNotes(e.target.value)}
              ></textarea>
              <p>Add any special instructions or notes for your order here...</p>
            </div>
            <div className="quantity-selector">
              <h4>Quantity</h4>
              <input 
                type="number" 
                value={quantity}
                onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value)))}
                min={1} 
                max={10} 
              />
              <p>Max. 1 pcs/shopper</p>
            </div>
            <button className="btn btn-outline-danger btn-block" onClick={handleAddToCart}>Add to cart</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
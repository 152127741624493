import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../css/adminDashboard.css';
import ProductTab from '../components/productsTab';
import OrdersTab from '../components/ordersTab';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('products');

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="container mt-5">
      <h1>Admin Dashboard</h1>
      <Tabs
        activeKey={activeTab}
        onSelect={handleTabSelect}
        id="admin-dashboard-tabs"
        className="mb-3 mt-4"
        fill
      >
        <Tab 
          eventKey="products" 
          title={<h5 style={{ color: 'black' }}>Products</h5>}
        >
          {activeTab === 'products' && <ProductTab />}
        </Tab>
        <Tab 
          eventKey="orders" 
          title={<h5 style={{ color: 'black' }}>Orders</h5>}
        >
          {activeTab === 'orders' && <OrdersTab />}
        </Tab>
      </Tabs>
    </div>
  );
};

export default AdminDashboard;
import React from 'react';

const PublicPage = () => {
  return (
    <div className="public-page">
      <header className="public-header">
        <h1>Welcome to Our Website</h1>
        <p>Your one-stop solution for all your needs</p>
      </header>

      <section className="about-us">
        <h2>About Us</h2>
        <p>
          We are a company dedicated to providing the best services to our customers.
          Our team is composed of experienced professionals who are passionate about their work.
        </p>
      </section>

      <section className="services">
        <h2>Our Services</h2>
        <ul>
          <li>Service 1: Description of service 1.</li>
          <li>Service 2: Description of service 2.</li>
          <li>Service 3: Description of service 3.</li>
        </ul>
      </section>

      <section className="contact-us">
        <h2>Contact Us</h2>
        <p>
          If you have any questions or need further information, feel free to contact us at:
        </p>
        <p>Email: contact@ourwebsite.com</p>
        <p>Phone: (123) 456-7890</p>
      </section>
    </div>
  );
};

export default PublicPage;

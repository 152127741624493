import React, { useState } from 'react';
import { Form, Button, Dropdown, Row, Col, Accordion } from 'react-bootstrap';
import { fieldTypes, createInitialArrayItem } from './utils';
import { renderArrayField, renderSingleField } from './fieldRenderers';
import { handleAddField, handleRemoveField, handleEditKey, handleEditField, handleEditArrayDescription } from './fieldHandlers';
import '../../css/JSONBuilder.css'

const JSONBuilder = ({ value, onChange }) => {
  const [newValue, setNewValue] = useState('');
  const [fieldType, setFieldType] = useState('single');
  const [enName, setEnName] = useState('');
  const [frName, setFrName] = useState('');
  const [enDescription, setEnDescription] = useState('');
  const [frDescription, setFrDescription] = useState('');
  const [activeKeys, setActiveKeys] = useState([]);

  const handleAddFieldWithMultilingualInfo = () => {
    if (enName) {
      let newField = {};
      if (fieldType.startsWith('array')) {
        const initialItem = createInitialArrayItem(fieldType);
        newField = {
          [enName]: [initialItem],
          [`${enName}_en_name`]: enName,
          [`${enName}_fr_name`]: frName,
          [`${enName}_en_description`]: enDescription,
          [`${enName}_fr_description`]: frDescription
        };
      } else {
        newField = {
          [enName]: newValue,
          [`${enName}_en_name`]: enName,
          [`${enName}_fr_name`]: frName,
          [`${enName}_en_description`]: enDescription,
          [`${enName}_fr_description`]: frDescription
        };
      }
      onChange({ ...value, ...newField });
      setEnName('');
      setFrName('');
      setEnDescription('');
      setFrDescription('');
      setNewValue('');
      setFieldType('single');
    }
  };

  const handleAccordionToggle = (eventKey) => {
    setActiveKeys((prevActiveKeys) => {
      if (prevActiveKeys.includes(eventKey)) {
        return prevActiveKeys.filter(key => key !== eventKey);
      } else {
        return [...prevActiveKeys, eventKey];
      }
    });
  };

  return (
    <div>
      <Accordion activeKey={activeKeys} alwaysOpen className="custom-color-accordion">
        {Object.entries(value).map(([fieldKey, fieldValue], index) => {
          if (fieldKey.endsWith('_name') || fieldKey.endsWith('_description')) {
            return null;
          }
          
          const eventKey = index.toString();
          
          return (
            <Accordion.Item eventKey={eventKey} key={fieldKey}>
              <Accordion.Header onClick={() => handleAccordionToggle(eventKey)}>
                {fieldKey}
              </Accordion.Header>
              <Accordion.Body>
                {Array.isArray(fieldValue)
                  ? renderArrayField(fieldKey, fieldValue, value, onChange)
                  : renderSingleField(fieldKey, fieldValue, value, onChange)
                }
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <Form>
        <Row className="my-3">
          <Col>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder="Enter key"
                value={enName}
                onChange={(e) => setEnName(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary" className="w-100">
                {fieldTypes[fieldType]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.entries(fieldTypes).map(([type, label]) => (
                  <Dropdown.Item key={type} onClick={() => setFieldType(type)}>
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={2} className="offset-5">
            <Button
              variant="primary"
              onClick={handleAddFieldWithMultilingualInfo}
              className="w-100"
            >
              Add Field
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default JSONBuilder;
// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
	apiKey: "AIzaSyAHzNbErBvzrqXZZzQbBOB7_RNp4tZ2AA4",
	authDomain: "custompartsgroup-954b0.firebaseapp.com",
	projectId: "custompartsgroup-954b0",
	storageBucket: "custompartsgroup-954b0.appspot.com",
	messagingSenderId: "712268507824",
	appId: "1:712268507824:web:1b77d6fedb2bd33d4ae975",
	measurementId: "G-TW5K37MJB3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

export { db, auth };

import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../AuthContext';
import ProductTable from '../components/ProductTable';
import ProductModal from '../components/ProductModal';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';
import { fetchProducts, addProduct, updateProduct, deleteProduct } from '../api/productApi';

const ProductTab = () => {
  const [products, setProducts] = useState([]);
  const [carOptions, setCarOptions] = useState([]);
  const [partsOptions, setPartsOptions] = useState([]);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [productToDelete, setProductToDelete] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalMode, setModalMode] = useState('add');
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchAndSetProducts = async () => {
      try {
        const fetchedProducts = await fetchProducts(getToken);
        setProducts(fetchedProducts);
      } catch (error) {
        console.error('Error in fetchAndSetProducts:', error);
      }
    };

    const fetchCars = async () => {
      const token = await getToken();
      try {
        const response = await fetch(`https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/cars`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        
        if (response.ok) {
          const data = await response.json();
          setCarOptions(data.options);
          setIsLoading(false);
          console.log('Cars retrieved successfully:', data.options);
        } else {
          console.error('Failed to retrieve cars:', response.statusText);
          setError('Failed to retrieve cars');
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error retrieving cars:', error);
        setError('Error retrieving cars');
        setIsLoading(false);
      }
    };

    const fetchParts = async () => {
      const token = await getToken();
      try {
        const response = await fetch(`https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/parts`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        
        if (response.ok) {
          const data = await response.json();
          setPartsOptions(data.options);
          setIsLoading(false);
          console.log('Parts retrieved successfully:', data.options);
        } else {
          console.error('Failed to retrieve parts:', response.statusText);
          setError('Failed to retrieve cars');
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error retrieving parts:', error);
        setError('Error retrieving parts');
        setIsLoading(false);
      }
    };
        
    fetchCars();
    fetchParts();
    fetchAndSetProducts();
  }, [getToken, refreshTrigger]);

  const refreshProducts = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  const handleDelete = (product) => {
    setProductToDelete(product);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteProduct(productToDelete.id, getToken);
      refreshProducts();
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleEdit = (product) => {
    setCurrentProduct(product);
    setModalMode('edit');
    setShowProductModal(true);
  };

  const handleAdd = () => {
    setCurrentProduct(null);
    setModalMode('add');
    setShowProductModal(true);
  };

  const handleSaveProduct = async (productData) => {
    try {
      if (modalMode === 'edit') {
        await updateProduct(productData, getToken);
      } else {
        await addProduct(productData, getToken);
      }
      refreshProducts();
      setShowProductModal(false);
    } catch (error) {
      console.error(`Error ${modalMode === 'edit' ? 'updating' : 'adding'} product:`, error);
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-end my-3 mx-2">
        <Button variant="primary" onClick={handleAdd}>
          <FontAwesomeIcon icon="plus" />
        </Button>
      </div>
      
      <ProductTable 
        products={products} 
        onEdit={handleEdit} 
        onDelete={handleDelete} 
      />

      <ProductModal
        show={showProductModal}
        onHide={() => setShowProductModal(false)}
        product={currentProduct}
        onSave={handleSaveProduct}
        carOptions={carOptions}
        partsOptions={partsOptions}
        mode={modalMode}
      />

      <DeleteConfirmationModal 
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default ProductTab;
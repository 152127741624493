import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShoppingCart } from '../contexts/ShoppingCartContext';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Form, Button, Table, Image, Alert } from 'react-bootstrap';
import '../css/checkoutPage.css';
import { useAuth } from '../AuthContext';
import { useLanguage } from '../contexts/LanguageProvider';

const CheckoutPage = () => {
  const { cart, cartTotal, clearCart, cartItemCount, updateQuantity, removeFromCart, toggleItemActive, activeItemCount, clearActiveItems } = useShoppingCart();
  const { language } = useLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser, role, login, logout, getToken } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    province: '',
    postalCode: '',
  });
  
  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedShipping, setSelectedShipping] = useState(null);
  const [isLoadingShippingRate, setIsLoadingShippingRate] = useState(false);
  const [shippingRateError, setShippingRateError] = useState(null);
  const [shippingPrice, setShippingPrice] = useState(0);

  useEffect(() => {
    if (currentUser) {
      // In a real application, you would fetch this data from your backend
      setFormData({
        name: currentUser.displayName || 'Frederick Pelletier-Goulet',
        address: '4720 Circle Road',
        city: 'Montreal',
        province: 'Quebec',
        postalCode: 'H3W1Z1',
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (formData.postalCode && cart.length > 0) {
      fetchShippingRate();
    }
  }, [formData.postalCode, cart]);

  useEffect(() => {
    console.log('selectedShipping updated:', selectedShipping);
    console.log('shippingPrice updated:', shippingPrice);
  }, [selectedShipping, shippingPrice]);

  const fetchShippingRate = async () => {
    setIsLoadingShippingRate(true);
    setShippingRateError(null);
    try {
      const token = await getToken();
      const response = await fetch('https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/get-shipping-rate', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          originPostalCode: 'H8Z1K6',
          destinationPostalCode: formData.postalCode,
          weight: cart.reduce((total, item) => total + (item.weight || 1) * item.quantity, 0),
        }),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data[`price-quote`]);
      if (data && Array.isArray(data[`price-quote`])) {
        setShippingOptions(data[`price-quote`]);
        setSelectedShipping(data[`price-quote`][0]); // Select the first option by default
        const price = getShippingPrice(data[`price-quote`][0]);
        console.log('Calculated shipping price:', price);
        setShippingPrice(price);
      } else {
        console.error('Unexpected response format:', data);
        throw new Error('Invalid shipping rate data received');
      }
    } catch (error) {
      console.error('Error fetching shipping rate:', error);
      setShippingRateError(error.message || 'Failed to fetch shipping rate');
    } finally {
      setIsLoadingShippingRate(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      alert('Please log in to complete your purchase.');
      return;
    }
    try {
      const token = await getToken();
      const orderData = {
        userId: currentUser.email,
        preferedLanguage: language,
        products: cart
        .filter(item => item.active)
        .map(item => ({
          id: item.id,
          name_en: item.name_en,
          name_fr: item.name_fr,
          selectedOptions: item.selectedOptions,
          quantity: item.quantity,
          price: item.price
        })),
        totalAmount: cartTotal,
        shippingAddress: "",
        paymentMethod: ""
      };
      console.log(JSON.stringify(orderData))

      const response = await fetch('https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/placeOrder', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(orderData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

    const result = await response.json();
      console.log('Order submitted:', result);
      clearActiveItems();
      navigate(`/order-confirmation/${result.id}`);
    } catch (error) {
      console.error('Error submitting order:', error);
      alert('There was an error submitting your order. Please try again.');
    }
  };

  const calculateTax = () => {
    const tps = cartTotal * 0.05;
    const tvq = cartTotal * 0.09975;
    return { tps, tvq };
  };

  const { tps, tvq } = calculateTax();
  const totalWithTax = cartTotal + tps + tvq;

  const formatPrice = (price) => {
    const numPrice = Number(price);
    return isNaN(numPrice) ? '0.00' : numPrice.toFixed(2);
  };

  if (!currentUser) {
    return (
      <Container className="mt-5">
        <Alert variant="warning">
          Please log in to view the checkout page.
          <Button variant="link" onClick={() => navigate('/login')}>Log In</Button>
        </Alert>
      </Container>
    );
  }

  const renderOptionValue = (key, value) => {
    if (typeof value === 'object' && value !== null) {
      // Handle object type options
      const title = value[`${t.language}_title`] || value.en_title || key;
      if (value.color) {
        // Handle color options
        return (
          <div className="d-flex align-items-center">
            <div 
              className="color-square" 
              style={{ 
                backgroundColor: value.color,
                width: '12px',
                height: '12px',
                marginRight: '5px',
                border: '1px solid #ddd'
              }}
            />
            <span>{title}</span>
          </div>
        );
      } else if (value.image) {
        // Handle image options
        return (
          <div className="d-flex align-items-center">
            <Image 
              src={value.image} 
              alt={title} 
              style={{ width: '20px', height: '20px', marginRight: '5px', objectFit: 'cover' }} 
            />
            <span>{title}</span>
          </div>
        );
      }
      // Handle other object options
      return <div className="d-flex align-items-center">{title}</div>;
    } else if (typeof value === 'string' && value.startsWith('#')) {
      // Handle legacy color options (direct color strings)
      return (
        <div className="d-flex align-items-center">
          <div 
            className="color-square" 
            style={{ 
              backgroundColor: value,
              width: '12px',
              height: '12px',
              marginRight: '5px',
              border: '1px solid #ddd'
            }}
          />
          <span>{key}</span>
        </div>
      );
    }
    // Handle simple string options
    return <div className="d-flex align-items-center">{value}</div>;
  };

  const addressStyle = {
    textAlign: 'left',
    margin: '0',
    marginLeft: '50px',
    fontSize: 14
  };

  const titleStyle = {
    textAlign: 'left',
    margin: '0',
    marginLeft: '50px',
  };

  const modifyButtonStyle = {
    textAlign: 'right',
    margin: '0',
    marginLeft: '50px',
    padding: '0 0 5px 0'
  };

  const customRadioStyle = {
    appearance: 'none',
    width: '20px',
    height: '20px',
    border: '2px solid #007bff',
    borderRadius: '3px',
    outline: 'none',
    marginRight: '10px',
    position: 'relative',
    cursor: 'pointer',
  };
  
  const customRadioCheckedStyle = {
    ...customRadioStyle,
    backgroundColor: '#007bff',
    '&::after': {
      content: '"\\2714"',
      color: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '14px',
    },
  };

  const getShippingPrice = (shipping) => {
    if (shipping && shipping['price-details'] && shipping['price-details']['due']) {
      const price = parseFloat(shipping['price-details']['due']);
      return isNaN(price) ? price : price.toFixed(2);
    }
    return 0;
  };

  const handleShippingSelection = (option) => {
    console.log('Selected shipping option:', option);
    setSelectedShipping(option);
    const price = getShippingPrice(option);
    console.log('Calculated shipping price:', price);
    setShippingPrice(price);
  };

  const handleRedirectToProduct = (itemId) => {
    navigate(`/product/${itemId}`);
  };

  return (
    <Container className="checkout-page mt-5">
      <Row>
        <Col md={8} className="border p-3 mb-3">
          <h1 className="mb-4">{t('order')} ({cart.length} {cart.length === 1 ? t('item') : t('items')})</h1>
          <div className="mb-3" style={{borderBottom: '1px solid #e7e7e7', borderTop: '1px solid #e7e7e7'}}>
            <div className="my-3 p-1">
              <Row>
                <Col xs={4} className="text-left">
                  <h5 style={titleStyle}>1. {t('shippingAddress')}</h5>
                </Col>
                <Col xs={4} className="text-left">
                  <p style={addressStyle}>{formData.name}</p>
                  <p style={addressStyle}>{formData.address}</p>
                  <p style={addressStyle}>{formData.city}, {formData.province} {formData.postalCode}</p>
                </Col>
                <Col xs={4}>
                  <Button style={modifyButtonStyle} variant="link">{t('modify')}</Button>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mb-4 p-3">
            {cart.map((item, index) => (
              <Row 
                key={`${item.id}-${JSON.stringify(item.selectedOptions)}`}
                className="mb-3 py-3 ${item.active ? '' : 'bg-light'}"
                style={{
                  border: '1px solid #e7e7e7',
                  borderRadius: '15px'
                }}
              >
                <Col xs={1} className="d-flex align-items-center justify-content-center">
                  <Form.Check 
                    type="checkbox" 
                    checked={item.active} 
                    onChange={() => toggleItemActive(item.id, item.selectedOptions)}
                  />
                </Col>
                <Col xs={3}>
                  <Image src={item.image} alt={item.name} fluid style={{ width: '150px', height: '150px' }} onClick={() => handleRedirectToProduct(item.id)}/>
                </Col>
                <Col xs={6}>
                  <h5 style={{ textAlign: 'left' }} onClick={() => handleRedirectToProduct(item.id)}>{item[`name_${language}`]}</h5>
                  {item.selectedOptions && Object.entries(item.selectedOptions).map(([key, value]) => (
                    <div key={key} className="selected-option">
                      {renderOptionValue(key, value)}
                    </div>
                  ))}
                  <p style={{ textAlign: 'left' }} className="text-success my-1">{t('inStock')}</p>

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }} className="mt-2">
                    <Form.Control
                        as="select"
                        value={item.quantity}
                        onChange={(e) => updateQuantity(item.id, item.selectedOptions, parseInt(e.target.value))}
                        style={{ width: '50px', height: '30px', textAlign: 'center', borderRadius: '15px', padding: '0rem .75rem' }}
                      >
                        {[...Array(10).keys()].map(num => (
                          <option key={num + 1} value={num + 1}>{num + 1}</option>
                        ))}
                    </Form.Control>
                    <button className="btn btn-link btn-sm" onClick={() => removeFromCart(item.id, item.selectedOptions)}>{t('delete')}</button>
                    <button className="btn btn-link btn-sm" onClick={() => toggleItemActive(item.id, item.selectedOptions)}>{t('putAside')}</button>
                  </div>
                </Col>
                <Col xs={2} className="text-right">
                  <h5 style={{ marginTop: '0' }}><strong>{(item.price * item.quantity).toFixed(2)} $</strong></h5>
                </Col>
              </Row>
            ))}
            <Row className="mt-4 pt-3" style={{ borderTop: '1px solid #e7e7e7' }}>
              <Col xs={9} className="text-right">
                <h5 style={titleStyle}>{t('subtotal')} ({activeItemCount } {activeItemCount  === 1 ? t('item') : t('items')}):</h5>
              </Col>
              <Col xs={3} className="text-right">
                <h5><strong>{cartTotal.toFixed(2)} $</strong></h5>
              </Col>
            </Row>
          </div>
          <div className="mb-3" style={{borderBottom: '1px solid #e7e7e7', borderTop: '1px solid #e7e7e7'}}>
            <div className="my-3 p-1">
            <Row>
                <Col xs={12} className="text-left mb-4">
                  <h5 style={titleStyle}>2. {t('shippingOptions')}</h5>
                </Col>
              </Row>
              {isLoadingShippingRate ? (
                <p>Loading shipping options...</p>
              ) : shippingRateError ? (
                <p className="text-danger">{shippingRateError}</p>
              ) : (
                shippingOptions.map((option) => (
                  <div key={option[`service-code`]} className="custom-radio mb-2 ms-4">
                    <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                      <input
                        type="radio"
                        id={`shipping-${option['service-code']}`}
                        name="shippingOption"
                        checked={selectedShipping && selectedShipping[`service-code`] === option[`service-code`]}
                        onChange={() => handleShippingSelection(option)}
                        style={selectedShipping && selectedShipping[`service-code`] === option[`service-code`] ? customRadioCheckedStyle : customRadioStyle}
                      />
                      <span style={{ marginLeft: '5px' }}>
                        {`${option[`service-name`]} - $${getShippingPrice(option)} (${option[`service-standard`][`expected-delivery-date`]})`}
                      </span>
                    </label>
                  </div>
                ))
              )}
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className="border p-3">
            <Button variant="warning" className="w-100 mb-3" onClick={handleSubmit}>
              {t('placeOrder')}
            </Button>
            <p className="small">{t('tos')}</p>
            <h5>{t('orderSummary')}</h5>
            <Table>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'left' }}>{t('items')}:</td>
                  <td style={{ textAlign: 'right' }}><strong>${formatPrice(cartTotal)}</strong></td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }}>{t('shippingFees')}:</td>
                  <td style={{ textAlign: 'right' }}><strong>${formatPrice(shippingPrice)}</strong></td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }}>{t('estimatedGST')}:</td>
                  <td style={{ textAlign: 'right' }}><strong>${formatPrice(tps)}</strong></td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }}>{t('estimatedPST')}:</td>
                  <td style={{ textAlign: 'right' }}><strong>${formatPrice(tvq)}</strong></td>
                </tr>
                <tr className="font-weight-bold">
                  <td style={{ textAlign: 'left' }}>{t('totalAmount')}:</td>
                  <td style={{ textAlign: 'right' }}><strong>${formatPrice(totalWithTax + shippingPrice)}</strong></td>
                </tr>
              </tbody>
            </Table>
          </div>
          {role === 'admin' && (
            <Alert variant="info" className="mt-3">
              Admin user detected. Additional options may be available.
            </Alert>
          )}
          <Button variant="link" className="mt-3" onClick={logout}>Log Out</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CheckoutPage;
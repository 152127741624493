import React, { createContext, useContext, useState, useEffect } from 'react';

const ShoppingCartContext = createContext();

export const useShoppingCart = () => useContext(ShoppingCartContext);

export const ShoppingCartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('shoppingCart');
    console.log('Initial load from localStorage:', savedCart);
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    console.log('Saving cart to localStorage:', cart);
    localStorage.setItem('shoppingCart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (item) => {
    console.log('Adding item to cart:', item);
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex((cartItem) =>
        cartItem.id === item.id &&
        JSON.stringify(cartItem.selectedOptions) === JSON.stringify(item.selectedOptions)
      );

      if (existingItemIndex !== -1) {
        return prevCart.map((cartItem, index) =>
          index === existingItemIndex
            ? { ...cartItem, quantity: cartItem.quantity + (item.quantity || 1) }
            : cartItem
        );
      } else {
        return [...prevCart, { ...item, quantity: item.quantity || 1, active: true }];
      }
    });
  };

  const removeFromCart = (itemId, selectedOptions) => {
    setCart((prevCart) => prevCart.filter((item) =>
      !(item.id === itemId && JSON.stringify(item.selectedOptions) === JSON.stringify(selectedOptions))
    ));
  };

  const updateQuantity = (itemId, selectedOptions, newQuantity) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === itemId && JSON.stringify(item.selectedOptions) === JSON.stringify(selectedOptions)
          ? { ...item, quantity: newQuantity }
          : item
      )
    );
  };

  const toggleItemActive = (itemId, selectedOptions) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === itemId && JSON.stringify(item.selectedOptions) === JSON.stringify(selectedOptions)
          ? { ...item, active: !item.active }
          : item
      )
    );
  };

  const clearCart = () => {
    setCart([]);
  };

  const clearActiveItems = () => {
    setCart((prevCart) => {
      const inactiveItems = prevCart.filter(item => !item.active);
      return inactiveItems.map(item => ({ ...item, active: true }));
    });
  };

  const cartItemCount = cart.reduce((total, item) => total + item.quantity, 0);

  const calculateCartTotal = () => {
    const total = cart.reduce((sum, item) => {
      if (item.active) {
        const itemTotal = Number(item.price) * Number(item.quantity);
        console.log(`Item ${item.id} total in context: ${itemTotal}`);
        return sum + itemTotal;
      }
      return sum;
    }, 0);
    console.log('Calculated cart total in context:', total);
    return total;
  };

  const cartTotal = calculateCartTotal();

  const activeItemCount = cart.filter(item => item.active).length;

  const value = {
    cart,
    addToCart,
    removeFromCart,
    updateQuantity,
    toggleItemActive,
    clearCart,
    clearActiveItems,
    cartItemCount,
    activeItemCount,
    cartTotal,
  };

  console.log('ShoppingCartProvider value:', value);

  return (
    <ShoppingCartContext.Provider value={value}>
      {children}
    </ShoppingCartContext.Provider>
  );
};
// api/productApi.js
export const fetchProducts = async (getToken) => {
	try {
	  const token = await getToken();
	  const response = await fetch('https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/products', {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Authorization': `Bearer ${token}`,
	      'Content-Type': 'application/json'
	    },
	    body: JSON.stringify({ action: 'fetchProducts' })
	  });
      
	  if (!response.ok) {
	    throw new Error(`Network response was not ok: ${response.statusText}`);
	  }
      
	  return await response.json();
	} catch (error) {
	  console.error('Error fetching products:', error);
	  throw error;
	}
      };
      
      export const addProduct = async (product, getToken) => {
	try {
		console.log(JSON.stringify(product))
	  const token = await getToken();
	  const response = await fetch('https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/addUpdateProducts', {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Authorization': `Bearer ${token}`,
	      'Content-Type': 'application/json'
	    },
	    body: JSON.stringify(product)
	  });
      
	  if (!response.ok) {
	    throw new Error(`Network response was not ok: ${response.statusText}`);
	  }
      
	  return await response.json();
	} catch (error) {
	  console.error('Error adding product:', error);
	  throw error;
	}
      };
      
      export const updateProduct = async (product, getToken) => {
	try {
	  console.log(JSON.stringify(product));
	  const token = await getToken();
	  const response = await fetch('https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/addUpdateProducts', {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Authorization': `Bearer ${token}`,
	      'Content-Type': 'application/json'
	    },
	    body: JSON.stringify(product)
	  });
      
	  if (!response.ok) {
	    throw new Error(`Network response was not ok: ${response.statusText}`);
	  }
      
	  return await response.json();
	} catch (error) {
	  console.error('Error updating product:', error);
	  throw error;
	}
      };
      
      export const deleteProduct = async (productId, getToken) => {
	try {
	  const token = await getToken();
	  const response = await fetch(`https://us-central1-custompartsgroup-954b0.cloudfunctions.net/api/products/${productId}`, {
	    method: 'DELETE',
	    headers: {
	      'Accept': 'application/json',
	      'Authorization': `Bearer ${token}`
	    }
	  });
      
	  if (!response.ok) {
	    throw new Error(`Network response was not ok: ${response.statusText}`);
	  }
      
	  return await response.json();
	} catch (error) {
	  console.error('Error deleting product:', error);
	  throw error;
	}
      };
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShoppingCart } from '../contexts/ShoppingCartContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, ListGroup, Image, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../contexts/LanguageProvider';
import '../css/shoppingCart.css';

const ShoppingCart = ({ isOpen, setIsOpen, isMobile }) => {
  const {
    cart,
    removeFromCart,
    updateQuantity,
    cartTotal,
    clearCart
  } = useShoppingCart();

  const { t } = useTranslation();
  const { language } = useLanguage();
  const navigate = useNavigate();

  const handleClose = () => setIsOpen(false);

  const handleCheckout = () => {
    setIsOpen(false);
    navigate('/checkout');
  };

  useEffect(() => {
    console.log('Cart updated:', cart);
    console.log('Cart total:', cartTotal);
  }, [cart, cartTotal]);

  const formatPrice = (price) => {
    const numPrice = Number(price);
    return isNaN(numPrice) ? '0.00' : numPrice.toFixed(2);
  };

  const calculateTotal = () => {
    return cart.reduce((total, item) => {
      const itemTotal = Number(item.price) * Number(item.quantity);
      console.log(`Item ${item.id} total: ${itemTotal}`);
      return total + itemTotal;
    }, 0);
  };

  const renderOptionValue = (key, value) => {
    if (typeof value === 'object' && value !== null) {
      // Handle object type options
      const title = value[`${t.language}_title`] || value.en_title || key;
      if (value.color) {
        // Handle color options
        return (
          <div className="d-flex align-items-center">
            <div 
              className="color-square" 
              style={{ 
                backgroundColor: value.color,
                width: '12px',
                height: '12px',
                marginRight: '5px',
                border: '1px solid #ddd'
              }}
            />
            <span>{title}</span>
          </div>
        );
      } else if (value.image) {
        // Handle image options
        return (
          <div className="d-flex align-items-center">
            <Image 
              src={value.image} 
              alt={title} 
              style={{ width: '20px', height: '20px', marginRight: '5px', objectFit: 'cover' }} 
            />
            <span>{title}</span>
          </div>
        );
      }
      // Handle other object options
      return <div className="d-flex align-items-center">{title}</div>;
    } else if (typeof value === 'string' && value.startsWith('#')) {
      // Handle legacy color options (direct color strings)
      return (
        <div className="d-flex align-items-center">
          <div 
            className="color-square" 
            style={{ 
              backgroundColor: value,
              width: '12px',
              height: '12px',
              marginRight: '5px',
              border: '1px solid #ddd'
            }}
          />
          <span>{key}</span>
        </div>
      );
    }
    // Handle simple string options
    return <div className="d-flex align-items-center">{value}</div>;
  };

  const calculatedTotal = calculateTotal();
  //console.log('Calculated total:', calculatedTotal);
  //console.log('Cart total from context:', cartTotal);

  return (
    <>
      <div className={`shopping-cart-sidebar ${isOpen ? 'open' : ''} ${isMobile ? 'mobile' : ''}`}>
        <div className="cart-header flex flex-col items-center justify-center h-full">
          <p className="text-lg font-semibold">{t('total')}</p>
          <p className="text-xl font-bold">{formatPrice(cartTotal)}</p>
        </div>
        <div className="cart-body">
          {cart.length === 0 ? (
            <p>{t('cartEmpty')}</p>
          ) : (
            <>
              <ListGroup variant="flush">
                {cart.map((item) => (
                  <ListGroup.Item key={`${item.id}-${JSON.stringify(item.selectedOptions)}`} className="py-2">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <h6 className="mb-0">{item[`name_${language}`]}</h6>
                      <strong><span>{formatPrice(item.price)}</span></strong>
                    </div>
                    <div className="d-flex align-items-center">
                      <Image src={item.image} alt={item.name} rounded style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '15px' }} />
                      <Col xs={7} className="ml-2">
                        {item.selectedOptions && Object.entries(item.selectedOptions).map(([key, value]) => (
                          <div key={key} className="selected-option">
                            {renderOptionValue(key, value)}
                          </div>
                        ))}
                      </Col>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <div>
                        <Form.Control
                          as="select"
                          value={item.quantity}
                          onChange={(e) => updateQuantity(item.id, item.selectedOptions, parseInt(e.target.value))}
                          style={{ width: '50px', height: '30px', textAlign: 'center', borderRadius: '15px', padding: '0rem .75rem' }}
                        >
                          {[...Array(10).keys()].map(num => (
                            <option key={num + 1} value={num + 1}>{num + 1}</option>
                          ))}
                        </Form.Control>
                      </div>
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => removeFromCart(item.id, item.selectedOptions)}
                        style={{ width: '40px', height: '30px', textAlign: 'center', borderRadius: '15px' }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <div className="mt-3">
                <Button variant="primary" className="w-100 mb-2" onClick={handleCheckout}>
                  {t('checkout')}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      {isMobile && isOpen && <div className="overlay" onClick={handleClose}></div>}
    </>
  );
};

export default ShoppingCart;
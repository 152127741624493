import React from 'react';
import { Form, Button, InputGroup, Row, Col } from 'react-bootstrap';
import { getArrayType } from './utils';
import { 
  handleEditKey, 
  handleEditField, 
  handleEditArrayDescription, 
  handleAddToArray, 
  handleRemoveFromArray,
  handleRemoveField
} from './fieldHandlers';

export const renderArrayField = (fieldKey, fieldValue, value, onChange) => {
  const arrayType = getArrayType(fieldValue);
  return (
    <div className="p-2 mb-3">
      <Row className="align-items-center">
        <Col xs={12} className="text-end">
          <Button variant="outline-danger" size="sm" onClick={() => handleRemoveField(fieldKey, value, onChange)}>
            Remove Field
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className='border-end pe-3'>
          <Form.Group controlId="formName" className="mb-2">
            <h6>EN</h6>
            <Form.Control
              placeholder="English Name"
              value={value[`${fieldKey}_en_name`] || ''}
              onChange={(e) => handleEditField(`${fieldKey}_en_name`, e.target.value, null, null, value, onChange)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="English description"
              value={value[`${fieldKey}_en_description`] || ''}
              onChange={(e) => handleEditField(`${fieldKey}_en_description`, e.target.value, null, null, value, onChange)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formName" className="mb-2">
            <h6>FR</h6>
            <Form.Control
              placeholder="French Name"
              value={value[`${fieldKey}_fr_name`] || ''}
              onChange={(e) => handleEditField(`${fieldKey}_fr_name`, e.target.value, null, null, value, onChange)}
            />
          </Form.Group>
          <Form.Group controlId="formName" className="mb-3">
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="French description"
              value={value[`${fieldKey}_fr_description`] || ''}
              onChange={(e) => handleEditField(`${fieldKey}_fr_description`, e.target.value, null, null, value, onChange)}
            />
          </Form.Group>
        </Col>
      </Row>
      {fieldValue.map((item, index) => (
        <Row key={`${fieldKey}-${index}`} className="mb-2 align-items-center g-0">
          	<Col xs={10} className="offset-1">
            		<Row className="mb-2 g-2">
                			{arrayType === 'color' ? (
						<>
						<Col xs={4} className="offset-2">
						<InputGroup>
							<InputGroup.Text className="p-0">
								<Form.Control
									type="color"
									value={item.color}
									onChange={(e) => {
										handleEditField(fieldKey, e.target.value, index, 'color', value, onChange);
									}}
									style={{ width: '50px', height: '38px', padding: '0', border: 'none' }}
								/>
							</InputGroup.Text>
							<Form.Control
								type="text"
								value={item.color}
								onChange={(e) => {
								const newColor = e.target.value.startsWith('#') ? e.target.value : `#${e.target.value}`;
									handleEditField(fieldKey, newColor, index, 'color', value, onChange);
								}}
								placeholder="#000000"
							/>
						</InputGroup>
						</Col>
						<Col xs={4}>
						<InputGroup>
							<InputGroup.Text>$</InputGroup.Text>
							<Form.Control
							type="number"
							placeholder="Cost"
							value={item.cost}
							onChange={(e) => handleEditField(fieldKey, parseFloat(e.target.value) || 0, index, 'cost', value, onChange)}
							/>
					      </InputGroup>
					      </Col>
					      </>
               				 ) : (
						<>
						<Col xs={4}>
							<Form.Control
								placeholder="English Title"
								value={item.en_title}
								onChange={(e) => handleEditField(fieldKey, e.target.value, index, 'en_title', value, onChange)}
							/>
						</Col>
						<Col xs={4}>
							<Form.Control
								placeholder="French Title"
								value={item.fr_title}
								onChange={(e) => handleEditField(fieldKey, e.target.value, index, 'fr_title', value, onChange)}
							/>
						</Col>
						<Col xs={3}>
						<InputGroup>
							<InputGroup.Text>$</InputGroup.Text>
							<Form.Control
								type="number"
								placeholder="Cost"
								value={item.cost}
								onChange={(e) => handleEditField(fieldKey, parseFloat(e.target.value) || 0, index, 'cost', value, onChange)}
							/>
                  				</InputGroup>
		  			</Col>
					</>
                		)}
              			<Col xs={1}>
					<Button 
					variant="outline-danger" 
					onClick={() => handleRemoveFromArray(fieldKey, index, value, onChange)}
					>
					X
					</Button>
              			</Col>
            		</Row>
          	</Col>
        </Row>
      ))}
      <Row className="mt-2">
        <Col xs={2} className="offset-5">
          <Button variant="outline-primary" size="sm" onClick={() => handleAddToArray(fieldKey, value, onChange)} className="w-100">
            Add Item
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export const renderSingleField = (fieldKey, fieldValue, value, onChange) => (
  <Row className="mb-2 align-items-center">
    <Col xs={5}>
      <Form.Control
        value={fieldKey}
        onChange={(e) => handleEditKey(fieldKey, e.target.value, value, onChange)}
      />
    </Col>
    <Col xs={5}>
      <Form.Control
        value={fieldValue}
        onChange={(e) => handleEditField(fieldKey, e.target.value, null, value, onChange)}
      />
    </Col>
    <Col xs={2}>
      <Button variant="outline-danger" size="sm" onClick={() => handleRemoveField(fieldKey, value, onChange)}>
        Remove
      </Button>
    </Col>
  </Row>
);